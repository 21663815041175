'use strict';

exports.__esModule = true;
exports['default'] = {
  optional: ' (optional)',
  required: '',
  add: 'Add',
  remove: 'Remove',
  up: 'Up',
  down: 'Down'
};
module.exports = exports['default'];