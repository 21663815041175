'use strict';

exports.__esModule = true;

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { 'default': obj }; }

var _deprecate = require('./deprecate');

var _deprecate2 = _interopRequireDefault(_deprecate);

var _createLocation2 = require('./createLocation');

var _createLocation3 = _interopRequireDefault(_createLocation2);

var _createBrowserHistory = require('./createBrowserHistory');

var _createBrowserHistory2 = _interopRequireDefault(_createBrowserHistory);

exports.createHistory = _createBrowserHistory2['default'];

var _createHashHistory2 = require('./createHashHistory');

var _createHashHistory3 = _interopRequireDefault(_createHashHistory2);

exports.createHashHistory = _createHashHistory3['default'];

var _createMemoryHistory2 = require('./createMemoryHistory');

var _createMemoryHistory3 = _interopRequireDefault(_createMemoryHistory2);

exports.createMemoryHistory = _createMemoryHistory3['default'];

var _useBasename2 = require('./useBasename');

var _useBasename3 = _interopRequireDefault(_useBasename2);

exports.useBasename = _useBasename3['default'];

var _useBeforeUnload2 = require('./useBeforeUnload');

var _useBeforeUnload3 = _interopRequireDefault(_useBeforeUnload2);

exports.useBeforeUnload = _useBeforeUnload3['default'];

var _useQueries2 = require('./useQueries');

var _useQueries3 = _interopRequireDefault(_useQueries2);

exports.useQueries = _useQueries3['default'];

var _Actions2 = require('./Actions');

var _Actions3 = _interopRequireDefault(_Actions2);

exports.Actions = _Actions3['default'];

// deprecated

var _enableBeforeUnload2 = require('./enableBeforeUnload');

var _enableBeforeUnload3 = _interopRequireDefault(_enableBeforeUnload2);

exports.enableBeforeUnload = _enableBeforeUnload3['default'];

var _enableQueries2 = require('./enableQueries');

var _enableQueries3 = _interopRequireDefault(_enableQueries2);

exports.enableQueries = _enableQueries3['default'];
var createLocation = _deprecate2['default'](_createLocation3['default'], 'Using createLocation without a history instance is deprecated; please use history.createLocation instead');
exports.createLocation = createLocation;